import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
// import * as actions from "../../../_redux/orders/ordersActions";
import { OrderEditDialogHeader } from "./OrderEditDialogHeader";
import { OrderEditForm } from "./OrderEditForm";
import { useOrdersUIContext, OrdersUIContextModel } from '../OrdersUIContext';
import axios from "axios";
import { OrderModel } from '../models/index';
import Swal from 'sweetalert2';
import { reference } from "@popperjs/core";
import { UserComplete } from '../../../../../../../../base_project_backend/src/api/interfaces/usuarios.interface';
import { RootState } from '../../../../../../setup/redux/RootReducer';
import { shallowEqual, useSelector } from 'react-redux';
import { ReferencesModel } from "../../references/models";

export interface OrderEditDialogProps {
  id?: number | null;
  show?: boolean;
  onHide?: () => void;
}
export const OrderEditDialog:React.FC<OrderEditDialogProps>=({ id, show, onHide }) => {
  // const { company }1 = useSelector((state) => state.auth);
  const userComplete: UserComplete = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserComplete
  // Orders UI Context

  const ordersUIContext = (useOrdersUIContext() as OrdersUIContextModel);
  const ordersUIProps = useMemo(() => {
    return {
      queryParams: ordersUIContext.queryParams,
      initOrder: ordersUIContext.initOrder,
      fetchOrder: ordersUIContext.fetchOrder,
      selectedOrder: ordersUIContext.selectedOrder,
      loadOrder: ordersUIContext.loadOrder,
      loadOrders: ordersUIContext.loadOrders,
      setSelectedOrder: ordersUIContext.setSelectedOrder,
      setIsLoading: ordersUIContext.setIsLoading
    };
  }, [ordersUIContext]);

  useEffect(() => {
    // server call for getting Order by id
    if (id) {
      ordersUIProps.fetchOrder((id as number));
    }
  }, [id]);

	const createOrder = async (order: OrderModel) => {
		try {
			console.log('order', order)
			const product = await axios.post(
				`${process.env.REACT_APP_API_URL}/product`,
				{
					name: order.reference,
					description: order.description,
					companyId : userComplete.company.id,
					// @ts-ignore
					factoryId: Number(order.factory),
					// @ts-ignore
					costSupplies: order.costSupplies,
					// @ts-ignore
					costManufact: order.costManufact,
					// @ts-ignore
					totalCost: order.totalCost,
					// @ts-ignore
					supplies: order.supplies,
					// @ts-ignore
					productPrice: order.productPrice,
				}
			)
			console.log('product', product);
			if (!product.data.id) {
				ordersUIProps.setIsLoading(false);
				Swal.fire({
					icon: "warning",
					title: product.data.description,
				})
				// .then(async ()=>{
				//   await ordersUIProps.loadOrders();
				//   (onHide as () => void)();
				// })
			} else {
				ordersUIProps.setIsLoading(false);
				await axios.post(
					`${process.env.REACT_APP_API_URL}/order`,
					{
						...order,
						values: JSON.stringify({
							price: order.totalCost,
							id: product.data.id,
							quantity: order.productQuantity,
							suggestedPrice: order.suggestedPrice

						}),
					}
				)
				Swal.fire({
					icon: "success",
					title: 'Orden de producción creada correctamente',
				}).then(async ()=>{
					await ordersUIProps.loadOrders();
					(onHide as () => void)();
				})
			}
		} catch (error) {
			console.log('error', error)
			ordersUIProps.setIsLoading(false);
			Swal.fire({
				icon: "error",
				title: 'Error al crear Orden de producción',
			}).then(()=>{
				ordersUIProps.loadOrders()
			})
		}
	}

	const updateOrder = async (order: OrderModel) => {
		try {
			console.log('order', order)
			await axios.put(
				`${process.env.REACT_APP_API_URL}/order/${order.id}`, { ...order }
			)
			ordersUIProps.setIsLoading(false);
			Swal.fire({
				icon: "success",
				title: 'Orden de producción actualizado correctamente',
			}).then(async ()=>{
				await ordersUIProps.loadOrders();
				(onHide as () => void)();
			})

		} catch (error) {
			console.log('error', error)
			ordersUIProps.setIsLoading(false);
			Swal.fire({
				icon: "error",
				title: 'Error al actualizar el Orden de producción',
			}).then(()=>{
				ordersUIProps.loadOrders()
			})
		}
	}

  // server request for saving order
  const saveOrder = async(order:OrderModel) => {
    ordersUIProps.setIsLoading(true);
    if (!id && !order.id) {
			await createOrder(order);
			return
    } 
		await updateOrder(order);
		return;
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={()=>{
        ordersUIProps.setSelectedOrder(undefined);
        (onHide as () => void)();
      }}
      aria-labelledby="example-modal-sizes-title-lg"
			backdrop="static"
    >
      <OrderEditDialogHeader id={(id as number)} />
      {
        id && (ordersUIProps.selectedOrder as OrderModel)?.id &&
        <OrderEditForm
          saveOrder={saveOrder}
          actionsLoading={ordersUIProps.loadOrder}
          order={(ordersUIProps.selectedOrder as OrderModel) || ordersUIProps.initOrder}
          onHide={onHide}
        />
      }
      {
        !id && <OrderEditForm
        saveOrder={saveOrder}
        actionsLoading={ordersUIProps.loadOrder}
        order={(ordersUIProps.selectedOrder as OrderModel) || ordersUIProps.initOrder}
        onHide={onHide}
      />
      }
    </Modal>
  );
}


